import React from 'react';
import SupportTemplate from './SupportTemplate/SupportTemplate';

const SupportGiven = () => {
    return (
        <div>
            <SupportTemplate />
        </div>
    );
};

export default SupportGiven;