import React from 'react';
import RoomGrid from './RoomGrid/RoomGrid';

const RoomElement = () => {
    return (
        <>
           <RoomGrid /> 
        </>
    );
};

export default RoomElement;