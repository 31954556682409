import React from 'react';

const Adinfo = () => {
    const reviewCustomar = {
        title : 'Contact Us for more information : +88 01810136609'
    }
    return (
        <div id="Tab4" className="tabcontent">
            <div className="room-detaits-main">
                <h6>{reviewCustomar.title}</h6>
            </div>
        </div>
    );
};

export default Adinfo;