export const DINE_MENU_TAB_BUTTON_NAMES = {
    BREAK_FAST: 'BREAK_FAST',
    LUNCH: 'LUNCH',
    DINNER: 'DINNER'
}

export const CHECKOUT_MENU_TAB_BUTTON_NAMES = {
    NEW_CUSTOMER: 'NEW_CUSTOMER',
    EXISTING_CUSTOMER: 'EXISTING_CUSTOMER',
}

export const GALLERY_MENU_TAB_BUTTON_NAMES = {
    SPACES : 'SPACES',
    ACTIVITIES : 'ACTIVITIES',
    POOLS : 'POOLS',
    RESTAURANTS : 'RESTAURANTS',
    SPA : 'SPA',
    REVIEWS : 'REVIEWS'
}

export const CONTEXT_KEYS = {
    CART_STORE_ROOM: 'CART_STORE_ROOM',
    CART_REMOVE_ROOM: 'CART_REMOVE_ROOM',
    CART_INITIATED: 'CART_INITIATED',
    CART_UPDATE_STORE: 'CART_UPDATE_STORE', 
    CART_CLEAR: 'CART_CLEAR'
}

export const LOCAL_STORAGE_KEY = {
    CART: 'CARTV1.0'
}

