import React from 'react';

const SmallLoder = () => {
    return (
        <div className='small-loder'>
            <div className="loader">
                <div className="spinner"></div>
            </div>
        </div>
    );
};

export default SmallLoder;